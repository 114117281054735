import {
    formatAppNameText,
    BonusPagesEnum,
    PolicyPagesEnum,
    WalletLayoutEnum,
    IBonusPagesConfig,
    IPolicyPagesConfig,
} from "@finbackoffice/fe-core";
import {
    useBonusPagesConfig,
    usePoliciesPagesConfig,
    useRuntimeConfig,
    useTranslation,
} from "@finbackoffice/site-core";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, memo, useCallback, useMemo } from "react";
import ActiveLink from "components/base/active-link/ActiveLink";
import ErrorBoundary from "components/base/error-boundary/ErrorBoundary";
import FadeInAnimation from "components/base/fade-in/FadeInAnimation";
import Img from "components/base/img/Img";
import { Svg } from "components/base/svg/Svg";
import Translate from "components/base/translate/Translate";
import OddsFormatSelector from "components/footer/odds-format-selector/OddsFormatSelector";
import { useHost } from "hooks";
import { RouteNames, RouterQuery } from "utils/constants";
import styles from "./footer.module.sass";
import LicenseLogo from "./license-logo/LicenseLogo";

const isProduction = NODE_ENV === "production";

const Footer: FC = () => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");
    const VERSION = useRuntimeConfig("VERSION");
    const router = useRouter();
    const { t } = useTranslation();
    const { origin } = useHost();
    const enabledPoliciesConfig = usePoliciesPagesConfig();
    const enabledBonusesConfig = useBonusPagesConfig();

    const renderFooterCurrenciesSection = useMemo(() => {
        if (COMMON_SITE_CONFIGS.wallet.type === WalletLayoutEnum.Pix) {
            return <Svg src="/common/footer/pix.svg" wrapper="span" className="svg-icon" />;
        }

        return (
            <>
                <Svg src="/common/footer/bitcoin.svg" wrapper="span" className="svg-icon" />
                <Svg src="/common/footer/ripple.svg" wrapper="span" className="svg-icon" />
                <Svg src="/common/footer/dogecoin.svg" wrapper="span" className="svg-icon" />
                <Svg src="/common/footer/litecoin.svg" wrapper="span" className="svg-icon" />
                <Svg src="/common/footer/tron.svg" wrapper="span" className="svg-icon" />
                <Svg src="/common/footer/usdt.svg" wrapper="span" className="svg-icon" />
                <Svg src="/common/footer/ethereum.svg" wrapper="span" className="svg-icon" />
            </>
        );
    }, [COMMON_SITE_CONFIGS.wallet.type]);

    const renderAgeRestriction = useMemo(() => <span className={styles.age}>18+</span>, []);

    const renderPoliciesLink = useCallback((item: IPolicyPagesConfig) => {
        let content;
        switch (item.name) {
            case PolicyPagesEnum.Privacy:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.POLICIES}/${RouteNames.PRIVACY}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_policies_privacy" />
                    </ActiveLink>
                );
                break;
            case PolicyPagesEnum.Terms:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.POLICIES}/${RouteNames.TERMS}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_policies_terms" />
                    </ActiveLink>
                );
                break;
            case PolicyPagesEnum.Sports:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.POLICIES}/${RouteNames.SPORTSBOOK}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_policies_rules" />
                    </ActiveLink>
                );
                break;
            case PolicyPagesEnum.Casino:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.CASINO}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_casino" />
                    </ActiveLink>
                );
                break;
            case PolicyPagesEnum.Payments:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.POLICIES}/${RouteNames.PAYMENTS}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_policies_payments" />
                    </ActiveLink>
                );
                break;
            case PolicyPagesEnum.ResponsibleGaming:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.POLICIES}/${RouteNames.RESPONSIBLE_GAMING}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_policies_responsible_gaming" />
                    </ActiveLink>
                );
                break;
            case PolicyPagesEnum.Cookies:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.POLICIES}/${RouteNames.COOKIES}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_policies_cookies" />
                    </ActiveLink>
                );
                break;
            case PolicyPagesEnum.SelfExclusion:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.POLICIES}/${RouteNames.SELF_EXCLUSION}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_policies_self_exclusion" />
                    </ActiveLink>
                );
                break;
            case PolicyPagesEnum.DisputeResolution:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.POLICIES}/${RouteNames.DISPUTE_RESOLUTION}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_policies_dispute_resolution" />
                    </ActiveLink>
                );
                break;
            case PolicyPagesEnum.AntiMoneyLaundering:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.POLICIES}/${RouteNames.ANTI_MONEY_LAUNDERING}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_policies_anti_money_laundering" />
                    </ActiveLink>
                );
                break;
            case PolicyPagesEnum.FairnessRngTesting:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.POLICIES}/${RouteNames.FAIRNESS_RNG_TESTING}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_policies_fairness_rng_testing" />
                    </ActiveLink>
                );
                break;
            case PolicyPagesEnum.Kyc:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.POLICIES}/${RouteNames.KYC}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_policies_kyc" />
                    </ActiveLink>
                );
                break;
            default:
                content = null;
        }
        return content;
    }, []);

    const renderBonusesLink = useCallback((item: IBonusPagesConfig) => {
        let content;
        switch (item.name) {
            case BonusPagesEnum.Referral:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.BONUSES}/${RouteNames.BONUS_REFERRAL}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_bonuses_referral" />
                    </ActiveLink>
                );
                break;
            case BonusPagesEnum.Cashback:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.BONUSES}/${RouteNames.CASHBACK}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_bonuses_cashback" />
                    </ActiveLink>
                );
                break;
            case BonusPagesEnum.Promotions:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.BONUSES}/${RouteNames.PROMOTIONS}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_bonuses_promotions" />
                    </ActiveLink>
                );
                break;
            case BonusPagesEnum.Casino:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.BONUSES}/${RouteNames.PROMOTIONS}/${RouterQuery.Casino}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_bonuses_promo_casino" />
                    </ActiveLink>
                );
                break;
            case BonusPagesEnum.Casino2:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.BONUSES}/${RouteNames.PROMOTIONS}/${RouterQuery.Casino2}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_bonuses_promo_casino_2" />
                    </ActiveLink>
                );
                break;
            case BonusPagesEnum.Promo2:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.BONUSES}/${RouteNames.PROMOTIONS}/${RouterQuery.Promo2}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_bonuses_promo_2" />
                    </ActiveLink>
                );
                break;
            case BonusPagesEnum.Promo3:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.BONUSES}/${RouteNames.PROMOTIONS}/${RouterQuery.Promo3}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_bonuses_promo_3" />
                    </ActiveLink>
                );
                break;
            case BonusPagesEnum.Promo4:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.BONUSES}/${RouteNames.PROMOTIONS}/${RouterQuery.Promo4}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_bonuses_promo_4" />
                    </ActiveLink>
                );
                break;
            case BonusPagesEnum.Promo5:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.BONUSES}/${RouteNames.PROMOTIONS}/${RouterQuery.Promo5}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_bonuses_promo_5" />
                    </ActiveLink>
                );
                break;
            case BonusPagesEnum.Promo6:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.BONUSES}/${RouteNames.PROMOTIONS}/${RouterQuery.Promo6}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_bonuses_promo_6" />
                    </ActiveLink>
                );
                break;
            default:
                content = null;
        }

        return item.enable ? content : null;
    }, []);

    return (
        <FadeInAnimation>
            <footer className={styles.footer} data-testid="footer">
                <div className={styles.footerLinks}>
                    <nav>
                        <h2>
                            <Translate tid="footer_sports" />
                        </h2>
                        <ActiveLink
                            href={`/${RouteNames.SPORT}/${RouteNames.PREMATCH}`}
                            activeClassName={styles.active}
                            includes={`/${RouteNames.SPORT}/${RouteNames.PREMATCH}`}>
                            <Translate tid="pageName_sport" />
                        </ActiveLink>
                        <ActiveLink
                            href={`/${RouteNames.SPORT}/${RouteNames.LIVE}`}
                            activeClassName={styles.active}
                            includes={`/${RouteNames.SPORT}/${RouteNames.LIVE}`}
                            forceActive={router.pathname.indexOf(RouteNames.CALENDAR) !== -1}>
                            <Translate tid="pageName_live" />
                        </ActiveLink>
                        <ActiveLink
                            href={`/${RouteNames.SPORT}/${RouteNames.CALENDAR}`}
                            activeClassName={styles.active}>
                            <Translate tid="pageName_calendar" />
                        </ActiveLink>
                    </nav>
                    <nav>
                        <h2>
                            <Translate tid="footer_casino" />
                        </h2>
                        <ActiveLink
                            href={`/${RouteNames.CASINO}/${RouteNames.LIVE_CASINO}`}
                            includes={`/${RouteNames.LIVE_CASINO}`}
                            activeClassName={styles.active}>
                            <Translate tid="pageName_livecasino" />
                        </ActiveLink>
                        <ActiveLink
                            href={`/${RouteNames.CASINO}/${RouteNames.SLOTS}`}
                            includes={`/${RouteNames.SLOTS}`}
                            activeClassName={styles.active}>
                            <Translate tid="pageName_slots" />
                        </ActiveLink>
                    </nav>
                    <nav>
                        <h2>
                            <Translate tid="footer_bonuses" />
                        </h2>
                        {enabledBonusesConfig.map(renderBonusesLink)}
                    </nav>
                    <nav>
                        <h2>
                            <Translate tid="footer_help_center" />
                        </h2>
                        {enabledPoliciesConfig.map(renderPoliciesLink)}
                    </nav>
                    <nav>
                        <h2>
                            <Translate tid="footer_odds" />
                        </h2>
                        <OddsFormatSelector />
                        <Link
                            href={
                                isProduction ? `${origin.replace("https://", "https://m.")}` : ""
                            }>
                            <Translate tid="footer_mobile" />
                        </Link>
                    </nav>
                </div>
                {COMMON_SITE_CONFIGS.wallet.type !== WalletLayoutEnum.Tola && (
                    <div className={styles.footerCurrencyLogos}>
                        {renderFooterCurrenciesSection}
                    </div>
                )}

                <div className={styles.socialLinksContainer}>
                    {renderAgeRestriction}
                    <nav>
                        {COMMON_SITE_CONFIGS.socialPlatforms.facebook && (
                            <a
                                target="_blank"
                                href={`https://facebook.com/${COMMON_SITE_CONFIGS.socialPlatforms.facebook}`}
                                className={styles.socialLink}>
                                <Svg
                                    src="/common/footer/facebook.svg"
                                    wrapper="span"
                                    className="svg-icon"
                                />
                            </a>
                        )}

                        {COMMON_SITE_CONFIGS.socialPlatforms.instagram && (
                            <a
                                target="_blank"
                                href={`https://instagram.com/${COMMON_SITE_CONFIGS.socialPlatforms.instagram}`}
                                className={styles.socialLink}>
                                <Svg
                                    src="/common/footer/instagram.svg"
                                    wrapper="span"
                                    className="svg-icon"
                                />
                            </a>
                        )}
                        {COMMON_SITE_CONFIGS.socialPlatforms.twitter && (
                            <a
                                target="_blank"
                                href={`https://twitter.com/${COMMON_SITE_CONFIGS.socialPlatforms.twitter}`}
                                className={styles.socialLink}>
                                <Svg
                                    src="/common/footer/twitter.svg"
                                    wrapper="span"
                                    className="svg-icon"
                                />
                            </a>
                        )}

                        {COMMON_SITE_CONFIGS.socialPlatforms.youtube && (
                            <a
                                target="_blank"
                                href={`https://youtube.com/@${COMMON_SITE_CONFIGS.socialPlatforms.youtube}`}
                                className={styles.socialLink}>
                                <Svg
                                    src="/common/footer/youtube.svg"
                                    wrapper="span"
                                    className="svg-icon"
                                />
                            </a>
                        )}
                        {COMMON_SITE_CONFIGS.socialPlatforms.telegram && (
                            <a
                                target="_blank"
                                href={`https://t.me/${COMMON_SITE_CONFIGS.socialPlatforms.telegram}`}
                                className={styles.socialLink}>
                                <Svg
                                    src="/common/footer/telegram.svg"
                                    wrapper="span"
                                    className="svg-icon"
                                />
                            </a>
                        )}
                        {COMMON_SITE_CONFIGS.socialPlatforms.tiktok && (
                            <a
                                target="_blank"
                                href={`https://tiktok.com/@${COMMON_SITE_CONFIGS.socialPlatforms.tiktok}`}
                                className={styles.socialLink}>
                                <Svg
                                    src="/common/footer/tiktok.svg"
                                    wrapper="span"
                                    className="svg-icon"
                                />
                            </a>
                        )}
                    </nav>
                </div>
                {COMMON_SITE_CONFIGS.showLicenseLogo && (
                    <ErrorBoundary name={LicenseLogo.name}>
                        <LicenseLogo />
                    </ErrorBoundary>
                )}
                <Translate
                    tid="footer_copyright_text"
                    dangerous
                    replace={{ app_name: COMMON_SITE_CONFIGS.appName }}>
                    <div className={styles.footerText} />
                </Translate>

                <div className={styles.footerLogo}>
                    <Img
                        source={`${ASSETS_URL}/${formatAppNameText(
                            COMMON_SITE_CONFIGS.appName,
                        )}/desktop/logo.svg`}
                        alt={`${COMMON_SITE_CONFIGS.appName} logo`}
                        title={`${COMMON_SITE_CONFIGS.appName} logo`}
                        width={0}
                        height={0}
                        style={{ width: "100%", height: "auto" }}
                    />
                    <h1>
                        <Translate
                            tid="footer_headText"
                            replace={{ app_name: COMMON_SITE_CONFIGS.appName }}
                        />
                    </h1>
                </div>
                <div className={styles.websiteTitle}>
                    {`© ${new Date().getFullYear()} ${COMMON_SITE_CONFIGS.appName}`}
                </div>
                <span className={styles.version}>{t("footer_version", { version: VERSION })}</span>
            </footer>
        </FadeInAnimation>
    );
};
export default memo(Footer);
